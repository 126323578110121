import { LitElement, html } from "lit";

export class menupage extends LitElement {
  async connectedCallback() {
    this.image = "../../public/images/gamela_carta_page-0001.jpg";
    super.connectedCallback();
    const img = document.querySelector("img");
    img.addEventListener("click", () => {
      console.log("cargada");
    });
  }

  render() {
    return html`
      <article class="menuSection">
        <img
          class="imagenCarta"
          src="https://firebasestorage.googleapis.com/v0/b/gamela-25701.appspot.com/o/gamela1.jpg?alt=media&token=b4c38c55-5d58-42a9-a106-6164782b4447"
          alt="page-1"
        />
        <img
          class="imagenCarta"
          src="https://firebasestorage.googleapis.com/v0/b/gamela-25701.appspot.com/o/gamela2.jpg?alt=media&token=4ee49b85-2143-4e97-ba48-e435c1dc1662"
          alt="page-2"
        />
        <img
          class="imagenCarta"
          src="https://firebasestorage.googleapis.com/v0/b/gamela-25701.appspot.com/o/gamela3.jpg?alt=media&token=e8d6c028-319b-4ae0-aaf6-c0fa8ced716c"
          alt="page-3"
        />
        <img
          class="imagenCarta"
          src="https://firebasestorage.googleapis.com/v0/b/gamela-25701.appspot.com/o/gamela4.jpg?alt=media&token=7995d818-6616-4806-ba0e-521742e4d168"
          alt="page-4"
        />
      </article>
    `;
  }

  createRenderRoot() {
    return this;
  }
}

customElements.define("menu-page", menupage);
