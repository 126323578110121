import { LitElement } from "lit";

export class HomePage extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
    <section class="mainSection">
      <h1>A GAMELA</h1>
      <a
        class="carta"
        href="/carta"
      >
      CARTA
      </a>
    </section>
  `;
  }
}

customElements.define("home-page", HomePage);
